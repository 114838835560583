import {createContext, ReactNode, useState} from 'react';
import {AxiosInstance} from "axios";

interface ApiContextInterface {
    error: boolean;
    loading: boolean;
    get: (url: string) => any;
    post: (url: string, data: any) => any;
    put: (url: string, data: any) => any;
    del: (url: string) => any;
}

interface ApiContextProviderProps {
    axiosInstance: AxiosInstance;
    children: ReactNode;
}

export const ApiContextProvider = ({ axiosInstance, children }: ApiContextProviderProps) => {
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    const request = async (method: 'get' | 'post' | 'put' | 'delete', url: string, data?: any) => {
        setLoading(true);
        try {
            const fullUrl = `${axiosInstance.defaults.baseURL}${url}?_=${new Date().getTime()}`;
            const response = await axiosInstance[method](fullUrl, data);
            if (response.status !== 200) return null;
            return response.data;
        } catch (err) {
            setError(true);
        } finally {
            setLoading(false);
        }
        return null;
    };



    const get = (url: string) => request('get', url);
    const post = (url: string, data: any) => request('post', url, data);
    const put = (url: string, data: any) => request('put', url, data);
    const del = (url: string) => request('delete', url);


    return (
        <ApiContext.Provider
            value={{
                error,
                loading,
                get,
                post,
                put,
                del
            }}>
            {children}
        </ApiContext.Provider>
    );

};

export const ApiContext = createContext<ApiContextInterface>(null!);
